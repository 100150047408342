<template>
  <div>
    <h1>Invoice #{{ $route.params.id }}</h1>
    <template v-if="Object.keys(invoice).length > 0">
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-4 font-weight-bold">Invoice date</div>
            <div class="col-8">{{ invoice.created_at | formatDate }}</div>
            <div class="col-4 font-weight-bold">Invoice total</div>
            <div class="col-8">
              <span v-if="invoice.currency.prefix">{{ invoice.currency.prefix }} </span>{{ invoice.total }}<span v-if="invoice.currency.suffix"> {{ invoice.currency.suffix }}</span>
            </div>
          </div>
        </div>
        <div class="col">
          <ul class="list-group mb-2">
            <li class="list-group-item" v-for="line in invoice.lines" :key="line.id">
              <div class="row">
                <div class="col">
                  <div>{{ line.description }}</div>
                  <div>
                    <small>{{ line.comment }}</small>
                  </div>
                </div>
                <div class="col-2">
                  <span v-if="invoice.currency.prefix">{{ invoice.currency.prefix }} </span>{{ line.amount }}<span v-if="invoice.currency.suffix"> {{ invoice.currency.suffix }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="Object.keys(payments).length > 0">
        <h2>Payments</h2>
        <ul class="list-group">
          <li class="list-group-item" v-for="payment in payments" :key="payment.id">
            <div class="row">
              <div class="col">{{ payment.created_at | formatDate }}</div>
              <div class="col">{{ payment.source }}</div>
              <div class="col">
                <span v-if="invoice.currency.prefix">{{ invoice.currency.prefix }} </span>{{ payment.amount }}<span v-if="invoice.currency.suffix"> {{ invoice.currency.suffix }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </template>
    <div class="card" v-if="showPaymentForm" :style="{ opacity: paymentFormLoaded ? 1 : 0}">
      <div class="card-body">
        <h3 class="card-title">Pay now</h3>
        <div ref="card" class="form-control mb-2">
          <!-- A Stripe Element will be inserted here. -->
        </div>

        <!-- We'll put the error messages in this element -->
        <div id="card-errors" role="alert"></div>

        <button class="btn btn-success" id="submit" @click="pay">Pay</button>
        <div class="alert alert-danger mt-2" v-if="stripeError">{{ stripeError }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      invoice: {},
      payments: {},
      stripe: null,
      card: null,
      clientSecret: "",
	  showPaymentForm: true,
	  paymentFormLoaded: false,
      stripeError: "",
    };
  },
  async mounted() {
    try {
      const { data } = await this.$http.get("/invoices/" + this.$route.params.id);
      this.invoice = data.invoice;
      this.payments = data.payments;
      if (!data.invoice.paid_date && data.stripe.publishable_key) {
        this.clientSecret = data.stripe.payment_intent_client_secret;
        this.stripe = Stripe(data.stripe.publishable_key);
        const elements = this.stripe.elements();
        this.card = elements.create("card");
        this.card.mount(this.$refs.card);
        this.card.on("change", ({ error }) => {
          const displayError = document.getElementById("card-errors");
          if (error) {
            displayError.textContent = error.message;
          } else {
            displayError.textContent = "";
          }
        });
        this.paymentFormLoaded = true;
      } else {
        this.showPaymentForm = false;
      }
    } catch (err) {
      this.showPaymentForm = false;
      this.$router.go(-1);
      return;
    }
  },
  methods: {
    pay() {
      let self = this;
      this.stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.card,
          },
          setup_future_usage: "off_session",
        })
        .then(async function (result) {
          if (result.error) {
            self.stripeError = result.error.message;
          } else {
            if (result.paymentIntent.status === "succeeded") {
              await self.$http.post("/invoices/" + self.$route.params.id + "/check_payment");
              const { data } = await self.$http.get("/invoices/" + self.$route.params.id);
              self.invoice = data.invoice;
              self.payments = data.payments;
              if (data.invoice.paid_date || !data.stripe.publishable_key) {
                self.showPaymentForm = false;
              }
            }
          }
        });
    },
  },
};
</script>